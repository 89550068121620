import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {FaPhoneAlt} from "react-icons/fa";

interface FooterComponentProps {
    language: string; // Change the type to string
}

const FooterComponent: React.FC<FooterComponentProps> = ({ language }) => {
    const [email, setEmail] = useState<string>('');
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate('/home');
    };

    const handleProductClick = () => {
        navigate('/product');
    };

    const handleContactClick = () => {
        window.location.href = 'tel:+37379985866'; // Replace with your actual number
    };

    return (
        <div className="flex flex-col">
            <footer className="w-full bg-gradient-to-r from-[#FA9884] via-[#FFB3A4] to-[#FFD4B5] text-white py-12 mt-[800px] sm:mt-0">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between items-start lg:items-center px-4 sm:px-6">
                    <div className="flex flex-col items-start mb-8 lg:mb-0">
                        <h3 className="text-2xl font-bold">B-Luron Moldova</h3>
                        <p className="mt-4 text-white">
                            {language === 'md'
                                ? 'Contactați-ne pentru mai multe întrebări sau informații suplimentare despre produsele noastre!'
                                : 'Подписывайтесь, чтобы получать последние новости о наших продуктах, специальные предложения и эксклюзивные скидки!'}
                        </p>
                        <div className="flex flex-col sm:flex-row mt-4 gap-4">
                            <div className="flex items-center gap-2 md:gap-4">
                                <FaPhoneAlt className="text-white text-lg md:text-xl drop-shadow-lg"/>
                                <p className="text-sm md:text-lg text-white font-bold tracking-wide drop-shadow-lg sm:w-[10px] lg:w-[180px]">
                                    Tel: +373 79985866
                                </p>
                                <button
                                    onClick={handleContactClick} // Call the contact handler
                                    className="bg-white text-[#E74646] py-1 md:py-2 px-3 md:px-4 rounded-full shadow-2xl hover:scale-105 transform transition duration-500 ease-in-out sm:ml-0">
                                    {language === 'md'
                                        ? 'Contactați-ne'
                                        : 'Связаться'}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="contact flex flex-col items-start mb-8 lg:mb-0">
                        <h3 className="text-lg font-semibold">{language === 'md' ? 'Despre noi' : 'О нас'}</h3>
                        <p className="mt-2 text-white">{language === 'md' ? 'Chișinău, Republica Moldova' : 'Кишинев, Республика Молдова'}</p>
                        <p className="text-white">{language === 'md' ? 'Suport 24/24' : 'Поддержка 24/24'}</p>
                        <p className="text-white">{language === 'md' ? 'Livrare Rapidă & Garanție' : 'Быстрая доставка и гарантия'}</p>
                    </div>

                    <div className="flex flex-col items-start">
                        <h3 className="text-lg font-semibold">{language === 'md' ? 'Navighează' : 'Cсылки'}</h3>
                        <ul className="mt-2 space-y-2">
                            <li>
                            <a href="#" className="text-white hover:text-[#E74646]" onClick={handleHomeClick}>
                                    {language === 'md' ? 'Acasă' : 'Главная'}
                                </a>
                            </li>
                            <li>
                                <a href="#" className="text-white hover:text-[#E74646]" onClick={handleProductClick}>
                                    {language === 'md' ? 'Achiziționează' : 'Купить'}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="container mx-auto mt-12 border-t border-pink-200 pt-6 text-center">
                    <p className="text-sm text-white">&copy; 2024 B-Luron Moldova - Toate drepturile rezervate</p>
                </div>
            </footer>
        </div>
    );
};

export default FooterComponent;
