import React, { useEffect, useState } from 'react';
import { FaPhoneAlt, FaHome } from 'react-icons/fa'; // Import FaHome icon
import { useNavigate } from 'react-router-dom';

// Define the props interface for the HeaderComponent
interface HeaderComponentProps {
    toggleLanguage: () => void;
    language: string;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ toggleLanguage, language }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [hasRotated, setHasRotated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scrolledPercentage = (scrollY / scrollHeight) * 100;

            setScrollPosition(scrolledPercentage);

            if (scrollY > 100 && !hasRotated) {
                setHasRotated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasRotated]);

    const getTranslatedText = (mdText: string, ruText: string): string => {
        return language === 'md' ? mdText : ruText;
    };

    const handleBuyClick = () => {
        navigate('/product');
    };

    const handleLearnMoreClick = () => {
        navigate('/learnmore');
    };

    const handleGoHomeClick = () => {
        navigate('/home');
    };

    // New function to handle the contact button click
    const handleContactClick = () => {
        window.location.href = 'tel:+37379985866'; // Replace with your actual number
    };

    return (
        <header className="relative overflow-hidden w-full p-0 m-0">
            {/* Top Scroll Progress Bar */}
            <div
                className="fixed top-0 left-0 h-2 bg-gradient-to-r from-[#E74646] to-[#FF8080] z-50"
                style={{ width: `${scrollPosition}%` }}
            ></div>

            {/* Top Border */}
            <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-[#E74646] to-[#FF8080] animate-pulse"></div>

            {/* Header Content */}
            <div className="w-full flex flex-col gap-8 items-center bg-gradient-to-r from-[#FA9884] via-[#FFB3A4] to-[#FFD4B5] py-12 lg:py-16">
                <div className="flex items-center justify-between w-full px-4 md:px-16 sm:ml-[-20px]">
                    <div className="flex items-center gap-2 md:gap-4">
                        <FaPhoneAlt className="text-white text-lg md:text-xl drop-shadow-lg"/>
                        <p className="text-sm md:text-lg text-white font-bold tracking-wide drop-shadow-lg sm:w-[10px] lg:w-[180px]">
                            Tel: +373 79985866
                        </p>
                        <button
                            onClick={handleContactClick} // Call the contact handler
                            className="bg-white text-[#E74646] py-1 md:py-2 px-3 md:px-4 rounded-full shadow-2xl hover:scale-105 transform transition duration-500 ease-in-out sm:ml-0">
                            {getTranslatedText('Contact', 'Свяжитесь')}
                        </button>
                    </div>
                    <div className="relative flex items-center gap-2">
                        <button
                            onClick={handleGoHomeClick}
                            className={`cumpara1 w-10 md:w-12 h-10 md:h-12 bg-[#FA9884] text-white rounded-full hover:scale-110 hover:shadow-2xl hover:bg-gradient-to-r from-[#FA9884] to-[#E74646] transition-all duration-500 ease-in-out transform 
                             flex items-center justify-center`} // Added flex for center alignment
                        >
                            <FaHome className="text-white text-2xl md:text-3xl" /> {/* White Home Icon */}
                        </button>
                        <button
                            onClick={toggleLanguage}
                            className={`cumpara w-10 md:w-12 h-10 md:h-12 sm:ml-[7px] md:ml-2 bg-[#FA9884] text-white flex items-center justify-center rounded-full hover:scale-110 hover:shadow-2xl hover:bg-gradient-to-r from-[#FA9884] to-[#E74646] transition-all duration-500 ease-in-out transform ${
                                language === 'md' ? 'bg-[#FA9884]' : 'bg-[#E74646]'
                            }`}
                        >
                            {language === 'md' ? 'RU' : 'MD'}
                        </button>
                    </div>

                </div>

                <div className="flex flex-col items-center text-center gap-6 md:gap-8 w-full px-4 md:px-16">
                    <button
                        className="text-white text-5xl md:text-8xl font-serif font-bold leading-tight tracking-wider drop-shadow-2xl transform hover:scale-105 transition-transform duration-700"
                        onClick={handleGoHomeClick}
                    >
                        {getTranslatedText('B-Luron', 'Б-Лурон')}
                    </button>
                    <p className="text-white text-lg md:text-2xl font-light tracking-wide max-w-4xl drop-shadow-md">
                        {getTranslatedText(
                            'Supliment inovativ pentru articulații, ce susține producția de lichid sinovial și regenerarea țesutului cartilaginos.',
                            'Инновационная добавка для суставов, поддерживающая выработку синовиальной жидкости и регенерацию хрящевой ткани.'
                        )}
                    </p>
                    <div className="flex flex-row gap-4">
                        <button
                            className="bg-gradient-to-r from-[#FA9884] to-[#E74646] text-white py-2 md:py-4 px-6 md:px-8 rounded-full shadow-2xl hover:scale-105 transform transition duration-500 ease-in-out"
                            onClick={handleBuyClick}
                        >
                            {getTranslatedText('Cumpără cu 45% reducere', 'Купить со скидкой 45%')}
                        </button>
                        <button
                            onClick={handleLearnMoreClick}
                            className="bg-white text-[#E74646] border-2 border-[#E74646] py-2 md:py-4 px-6 md:px-8 rounded-full shadow-md hover:bg-[#FF8080] hover:text-white transition duration-500 ease-in-out"
                        >
                            {getTranslatedText('Află mai multe', 'Узнать больше')}
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderComponent;
