import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import ProductPageComponent from './components/ProductPageComponent/ProductPageComponent';
import FooterComponent from './components/FooterComponent/FooterComponent';
import FormularComponent from './components/FormularComponent/FormularComponent';
import ProductInfoComponent from './components/ProductInfoComponent/ProductInfoComponent';
import CarouselComponent from './components/CarouselComponent/CarouselComponent';
import initFacebookPixel from './FBPixel/facebookPixel';

function App() {
    const [language, setLanguage] = useState<'md' | 'ru'>('md');

    // Function to toggle the language state
    const toggleLanguage = () => {
        setLanguage(prevLanguage => (prevLanguage === 'md' ? 'ru' : 'md'));
    };

    // Initialize Facebook Pixel
    useEffect(() => {
        const pixelId = '1666005727312951'; // Replace with your Pixel ID
        initFacebookPixel(pixelId);
    }, []);

    // Layout Component for Reusability
    const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
        <>
            <HeaderComponent toggleLanguage={toggleLanguage} language={language} />
            {children}
            <FooterComponent language={language} />
        </>
    );

    return (
        <Router>
            <div className="App">
                <Routes>
                    {/* Root Route */}
                    <Route
                        path="/"
                        element={
                            <Layout>
                                <CarouselComponent language={language} />
                                <ProductInfoComponent language={language} />
                            </Layout>
                        }
                    />
                    {/* Redirect `/home` to `/` */}
                    <Route path="/home" element={<Navigate to="/" replace />} />

                    {/* Product Route */}
                    <Route
                        path="/product"
                        element={
                            <Layout>
                                <ProductPageComponent language={language} />
                            </Layout>
                        }
                    />

                    {/* Buy Route */}
                    <Route
                        path="/buy"
                        element={
                            <>
                                <HeaderComponent toggleLanguage={toggleLanguage} language={language} />
                                <FormularComponent language={language} />
                                <FooterComponent language={language} />
                            </>
                        }
                    />

                    {/* Learn More Route */}
                    <Route
                        path="/learnmore"
                        element={
                            <Layout>
                                <ProductInfoComponent language={language} />
                            </Layout>
                        }
                    />

                    {/* Carousel Product Route */}
                    <Route
                        path="/carouselproduct"
                        element={
                            <Layout>
                                <ProductPageComponent language={language} />
                            </Layout>
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
