import React, { useState } from 'react';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';
import { MdOutlineLocalOffer } from 'react-icons/md'; // Offer icon
import { AiOutlineShoppingCart } from 'react-icons/ai'; // Shopping cart icon
import { BiCheckCircle } from 'react-icons/bi'; // Check mark icon
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';

// Define the props type
interface CarouselComponentProps {
    language: string;
}

const CarouselComponent: React.FC<CarouselComponentProps> = ({ language }) => {
    const images = [
        require('../../assets/images/primabluron.jpg'),  // Ensure these images are resized to 700x800
        require('../../assets/images/doileabluron.jpg'),
    ];

    const navigate = useNavigate();

    const translations = {
        productTitle: {
            md: "Supliment pentru sănătatea articulațiilor B-Luron",
            ru: "Б-Лурон Добавка для здоровья суставов"
        },
        productDescription: {
            md: `Acţiunea produsului este îndreptată către sursa problemei. Complexul ortomolecular corectează dezechilibrul la nivel molecular, optimizând procesele biochimice din organism. Acţionează asupra tuturor articulaţiilor. Se poate combina cu fizioterapia şi orice produs medicamentoase. Acţiune prelungită (1 cură de tratament la 3-5 luni). Fără efecte secundare. Formă avantajoasă de prezentare şi administrare.`,
            ru: `Действие продукта направлено на источник проблемы. Ортобиомолекулярный комплекс корректирует дисбаланс на молекулярном уровне, оптимизируя биохимические процессы в организме. Действует на все суставы. Можно сочетать с физиотерапией и любыми лекарственными средствами. Длительное действие (1 курс лечения на 3-5 месяцев). Без побочных эффектов. Выгодная форма подачи и применения.`
        },
        originalPrice: {
            md: "4499.00 lei",
            ru: "4499.00 лей"
        },
        salePrice: {
            md: "2499.00 lei",
            ru: "2499.00 лей"
        },
        economize: {
            md: "Economisești 2000.00 lei",
            ru: "Экономите 2000.00 лей"
        },
        buyText: {
            md: "CUMPĂRĂ",
            ru: "КУПИТЬ"
        },
        blackFridayText: {
            md: "BLACK FRIDAY!",
            ru: "ЧЕРНАЯ ПЯТНИЦА!"
        },
        popularProduct: {
            md: "#Produs popular",
            ru: "#Популярный продукт"
        },
        fullStock: {
            md: "#Stock plin",
            ru: "#Полный склад"
        },
        tutorial: {
            md:"Urmărește tutorialul de achiziționare a produsului!!!",
            ru:"Следуйте инструкциям, чтобы приобрести продукт!!!"
        },
        warning: {
            md:"Atenție, produsul include 2 sticle a câte 500ML!!!",
            ru:"Внимание, продукт включает 2 бутылки по 500 мл!!!"
        }



    };

    const getTranslatedText = (mdText: string, ruText: string): string => {
        return language === 'md' ? mdText : ruText;
    };

    const [selectedStars, setSelectedStars] = useState(0);

    const handleStarClick = (index: number) => {
        setSelectedStars(index);
    };

    const handleBuyButton = () => {
        navigate('/buy');
    }

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        centerPadding: '0',
    };

    return (
        <div className="flex flex-col md:flex-row justify-between w-full h-full bg-gradient-to-r from-[#FA9884] to-[#FFE5CA] shadow-lg">
            <div className="w-full md:w-1/2 flex justify-center items-center p-4 md:p-0 h-full">
                <div className="h-full w-full relative flex items-center">
                    <Slider {...settings} className="w-full h-full">
                        {images.map((img, index) => (
                            <div key={index} className="h-full w-full flex items-center justify-center m-0">
                                <img
                                    src={img}
                                    alt={`Product Image ${index + 1}`}
                                    className="w-full h-[757px] object-cover transition-transform duration-500 hover:scale-105 shadow-md m-0"
                                />
                                <div className="absolute bottom-4 left-4 flex gap-4">
                                    <span
                                        className="bg-[#FF9900] text-white text-lg font-semibold py-2 px-4 rounded-full flex items-center shadow-lg">
                                        <BiCheckCircle className="mr-2"/>
                                        {getTranslatedText(translations.popularProduct.md, translations.popularProduct.ru)}
                                    </span>
                                    <span
                                        className="bg-[#FFC107] text-white text-lg font-semibold py-2 px-4 rounded-full flex items-center shadow-lg">
                                        <BiCheckCircle className="mr-2"/>
                                        {getTranslatedText(translations.fullStock.md, translations.fullStock.ru)}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            <div className="w-full md:w-1/2 flex flex-col h-full p-4 md:p-[100px] gap-[20px] md:gap-[40px]">
                <div className="flex-1 bg-white p-4 md:p-6 rounded-lg shadow-md flex flex-col justify-between">
                    <div>
                        <h2 className="text-2xl md:text-4xl font-bold text-[#921A40] border-b-2 border-[#E74646] pb-2 mb-4">
                            {getTranslatedText(translations.productTitle.md, translations.productTitle.ru)}
                        </h2>
                        <p className="text-[#57033F] text-sm md:text-lg mb-4 leading-relaxed">
                            {getTranslatedText(translations.productDescription.md, translations.productDescription.ru).split('\n').map((line, index) => (
                                <span key={index} className="block mb-2">
                                    {line.trim()}
                                </span>
                            ))}
                        </p>
                        <div className="flex items-center justify-center mt-4">
                            {[...Array(5)].map((_, index) => (
                                <FaStar
                                    key={index}
                                    className={`text-2xl md:text-4xl mx-1 cursor-pointer hover:text-[#FFD700] transition-colors duration-300 ${index < selectedStars ? 'text-[#FFD700]' : 'text-gray-300'}`}
                                    onClick={() => handleStarClick(index + 1)}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div
                    className="flex justify-between items-center mt-4 p-4 md:p-6 bg-gradient-to-r from-[#FA9884] to-[#FFA5CA] rounded-lg shadow-md">
                    <div className="flex flex-col items-start">
                        <div className="flex items-center mb-2">
                            <MdOutlineLocalOffer className="text-2xl md:text-3xl text-[#E74646] mr-2"/>
                            <p className="text-base md:text-lg font-bold line-through text-gray-600">{getTranslatedText(translations.originalPrice.md, translations.originalPrice.ru)}</p>
                        </div>
                        <p className="text-2xl md:text-4xl font-bold text-[#E74646]">{getTranslatedText(translations.salePrice.md, translations.salePrice.ru)}</p>
                        <span className="text-white text-base md:text-lg font-semibold">{getTranslatedText(translations.economize.md, translations.economize.ru)}</span>
                        <span className="text-[#921A40] text-base md:text-sm font-semibold">{getTranslatedText(translations.warning.md, translations.warning.ru)}</span>
                    </div>

                    <div className="flex flex-col space-y-4">
                        <button
                            className="bg-[#921A40] py-2 md:py-3 px-4 md:px-6 rounded-md text-lg md:text-xl font-bold text-white flex items-center justify-center hover:bg-[#F7418F] transition duration-300"
                            onClick={handleBuyButton}
                        >
                            <AiOutlineShoppingCart className="mr-2"/>
                            {getTranslatedText(translations.buyText.md, translations.buyText.ru)}
                        </button>
                        <button
                            className="bg-[#E74646] py-2 md:py-3 px-4 md:px-6 rounded-md text-lg md:text-xl font-bold text-white flex items-center justify-center hover:bg-[#F7418F] transition duration-300">
                            {getTranslatedText(translations.blackFridayText.md, translations.blackFridayText.ru)}
                        </button>
                    </div>
                </div>
                <a href="https://fb.watch/vTnNKBSPSQ/"
                   target="_blank"
                   className="text-[#921A40] hover:text-[#E74646]">
                    {getTranslatedText(translations.tutorial.md, translations.tutorial.ru)}
                </a>


            </div>
        </div>
    );
};

export default CarouselComponent;
