import React, { useState, useEffect } from 'react';
import bottleImage from "../../assets/images/bluron.jpg";
import formulaImage from "../../assets/images/acidhialuronic.jpg";
import { FaLeaf, FaSmile, FaWater } from "react-icons/fa";

interface ProductInfoComponentProps {
    language: string;
}

const ProductInfoComponent: React.FC<ProductInfoComponentProps> = ({ language }) => {
    const [hasRotated, setHasRotated] = useState(false);
    const [formulaVisible, setFormulaVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // Trigger animations with useEffect
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true); // Make the entire component visible with animation
            setTimeout(() => {
                setHasRotated(true); // Rotate the bottle
                setTimeout(() => {
                    setFormulaVisible(true); // Make formula visible after bottle rotation
                }, 1000); // Delay for formula appearance (1 second after bottle rotation)
            }, 500); // Initial delay for rotation
        }, 300); // Delay before making the component visible
        return () => clearTimeout(timer); // Cleanup timer
    }, []);

    const getTranslatedText = (mdText: string, ruText: string): string => {
        return language === 'md' ? mdText : ruText;
    };

    const handleSmoothScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const target = document.querySelector('#beneficii');
        if (target) {
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    return (
        <div
            className={`transition-opacity duration-1000 * 5 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'}`}>
            <div
                className="w-full flex flex-col md:flex-row justify-between items-center gap-16 py-16 bg-gradient-to-br from-[#FFD4B5] via-[#FFC4A4] to-[#FA9884] shadow-2xl relative overflow-hidden">
                <div className="w-full md:w-1/2 flex justify-center items-center relative z-10 gap-8 sm: ml-[-70px]">
                    <div
                        className={`relative z-20 transition-transform duration-1000 ${hasRotated ? 'rotate-[360deg]' : ''}`}>
                        <img
                            src={bottleImage}
                            alt="B-Luron Bottle"
                            className="w-auto h-auto max-w-lg drop-shadow-none hover:scale-105 transition-transform duration-500"
                        />
                    </div>

                    {formulaVisible && (
                        <div
                            className={`absolute w-[16rem] h-auto transition-all duration-500 ease-in-out`}
                            style={{left: '47%', top: '45%', transform: 'translateY(-50%)'}}
                        >
                            <div className="bg-white rounded-lg shadow-[0_8px_30px_rgba(0,0,0,0.5)] p-4">
                                <img
                                    src={formulaImage}
                                    alt="Hyaluronic Acid Formula"
                                    className="w-full h-auto"
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div className="w-full md:w-1/2 flex flex-col items-start gap-6 px-8 relative z-10">
                    <h2 className="text-5xl font-semibold text-[#E74646] leading-snug">
                        {getTranslatedText('Proprietăți inovative ale Acidului Hialuronic', 'Инновационные свойства гиалуроновой кислоты')}
                    </h2>
                    <p className="text-xl text-[#8A8A8A] leading-relaxed">
                        {getTranslatedText(
                            'Acidul hialuronic contribuie la menținerea hidratării pielii și oferă suport pentru sănătatea articulațiilor. Fiind parte a produselor cosmetice, acesta asigură elasticitate și fermitate pielii și contribuie la netezirea ridurilor.',
                            'Гиалуроновая кислота способствует сохранению увлажненности кожи и поддерживает здоровье суставов. Будучи частью косметических продуктов, она обеспечивает эластичность и упругость кожи, а также способствует разглаживанию морщин.'
                        )}
                    </p>
                    <ul className="list-disc pl-6 text-[#8A8A8A] text-lg space-y-2">
                        {[getTranslatedText('Hidratarea și elasticitatea pielii', 'Увлажнение и эластичность кожи'),
                            getTranslatedText('Protecția fibrelor de colagen și elastină', 'Защита волокон коллагена и эластина'),
                            getTranslatedText('Utilizare variată: injecții, fiole, creme', 'Разнообразное применение: инъекции, ампулы, кремы')
                        ].map((item, idx) => (
                            <li key={idx} className="hover:text-[#E74646] transition-colors duration-300">
                                {item}
                            </li>
                        ))}
                    </ul>
                    <a
                        href="#beneficii"
                        onClick={handleSmoothScroll}
                        className="mt-6 bg-gradient-to-r from-[#E74646] to-[#FF8080] text-white py-3 px-8 rounded-full shadow-2xl hover:scale-105 transform transition duration-500 ease-in-out"
                    >
                        {getTranslatedText('Descoperă toate beneficiile', 'Узнайте все преимущества')}
                    </a>
                </div>
            </div>

            {/* Beneficii Principale Section */}
            <div
                id="beneficii"
                className="flex flex-col items-center text-center gap-12 mt-10 h-[450px] w-full px-8"
            >
                <h2 className="text-6xl font-semibold text-[#E74646]">
                    {getTranslatedText('Beneficii Principale', 'Основные преимущества')}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full">
                    {[{
                        icon: FaWater,
                        titleMd: 'Hidratare Intensă',
                        titleRu: 'Интенсивное увлажнение',
                        textMd: 'Acidul hialuronic reține apa la suprafața pielii, menținând-o hidratată.',
                        textRu: 'Гиалуроновая кислота удерживает воду на поверхности кожи, поддерживая ее увлажненной.',
                    },
                        {
                            icon: FaSmile,
                            titleMd: 'Netezirea Ridurilor',
                            titleRu: 'Разглаживание морщин',
                            textMd: 'Ajută la umplerea ridurilor și cicatricilor cauzate de acnee.',
                            textRu: 'Помогает заполнить морщины и рубцы, вызванные акне.',
                        },
                        {
                            icon: FaLeaf,
                            titleMd: 'Protecția Colagenului',
                            titleRu: 'Защита коллагена',
                            textMd: 'Protejează fibrele de colagen și elastină, conferind pielii fermitate și suplețe.',
                            textRu: 'Защищает волокна коллагена и эластина, придавая коже упругость и эластичность.',
                        },
                    ].map(({icon: Icon, titleMd, titleRu, textMd, textRu}, idx) => (
                        <div
                            key={idx}
                            className="flex flex-col items-center p-8 bg-gradient-to-br from-[#FFE5CA] to-[#FFF1F0] shadow-2xl rounded-3xl border-t-4 border-[#FA9884] transition-transform duration-500 hover:scale-110 hover:bg-gradient-to-br from-[#FFD4B5] to-[#FA9884] hover:border-[#E74646] transform"
                        >
                            <Icon
                                className="text-6xl text-[#FA9884] mb-4 transition duration-500 group-hover:text-white"/>
                            <h3 className="text-3xl font-semibold text-[#E74646] mb-2 hover:text-white transition duration-500">
                                {getTranslatedText(titleMd, titleRu)}
                            </h3>
                            <p className="text-[#8A8A8A] text-lg hover:text-white transition duration-500">
                                {getTranslatedText(textMd, textRu)}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default ProductInfoComponent;
