import React, { useState, ReactNode, useEffect } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { FaRegEnvelope } from 'react-icons/fa';
import {useNavigate} from "react-router-dom"; // Email icon

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAlC3koVrLBAVC8F2KYlJAU9Ws7Mi5qRsM",
    authDomain: "bluron-59fca.firebaseapp.com",
    projectId: "bluron-59fca",
    storageBucket: "bluron-59fca.appspot.com",
    messagingSenderId: "907732666770",
    appId: "1:907732666770:web:105268141850535258594b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const FormularComponent: React.FC<{ language: string }> = ({ language }) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [adresaPostala, setAdresaPostala] = useState('');
    const [nrTelefon, setNrTelefon] = useState('');
    const [adresa, setAdresa] = useState('');
    const [orasul, setOrasul] = useState('');
    const [circlesArray, setCirclesArray] = useState<ReactNode[]>([]);

    const navigate = useNavigate();

    const handleHomeButton = () => {
        navigate('/home');
    }
    const translations = {
        title: {
            md: "Completează Formularul",
            ru: "Заполните форму"
        },
        placeholderName: {
            md: "Nume",
            ru: "Имя"
        },
        placeholderSurname: {
            md: "Prenume",
            ru: "Фамилия"
        },
        placeholderPostalCode: {
            md: "Codul Poștal",
            ru: "Почтовый код"
        },
        placeholderPhoneNumber: {
            md: "Număr Telefon",
            ru: "Номер телефона"
        },
        placeholderAddress: {
            md: "Adresa",
            ru: "Адрес"
        },
        placeholderCity: {
            md: "Orașul",
            ru: "Город"
        },
        submitButton: {
            md: "Trimite",
            ru: "Отправить"
        },
        homeButton: {
            md: "Acasă",
            ru: "Главная"
        },
        acasaPar: {
            md: "Apasă butonul ,,Acasă'' pentru a naviga către pagina principală",
            ru: "Нажмите кнопку «Главная», чтобы перейти на главную страницу."
        },
        alertTrans: {
            md: "Formularul Dvs a fost trimis cu succes!",
            ru: "Ваша форма успешно отправлена!"
        },
        alertTrans1: {
            md: "Nu ați reușit să trimiteți formularul, vă rugăm să încercați din nou!",
            ru: "Вам не удалось отправить форму, попробуйте еще раз!"
        }
    };

    useEffect(() => {
        const circles = [...Array(15)].map((_, i) => {
            const size = Math.random() * 80 + 10;
            const delay = Math.random() * 15 + 's';
            const duration = Math.random() * 20 + 15 + 's';
            const left = Math.random() * 80 + '%';

            return (
                <li
                    key={i}
                    className="absolute block list-none bg-gradient-to-r from-[#FD7F2C] via-[#FD9346] to-[#FDB777] bg-opacity-100 animate-animate bottom-[-150px]"
                    style={{
                        width: size,
                        height: size,
                        animationDelay: delay,
                        animationDuration: duration,
                        left: left,
                    }}
                ></li>
            );
        });
        setCirclesArray(circles);
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'formularData'), {
                name,
                surname,
                adresaPostala,
                nrTelefon,
                adresa,
                orasul
            });

            // Alert with the custom success message
            alert(getTranslatedText(translations.alertTrans.md, translations.alertTrans.ru));

            // Clear form fields
            setName('');
            setSurname('');
            setAdresaPostala('');
            setNrTelefon('');
            setAdresa('');
            setOrasul('');
        } catch (error) {
            console.error('Error adding document: ', error);
            alert(getTranslatedText(translations.alertTrans1.md, translations.alertTrans1.ru));
        }
    };



    const getTranslatedText = (mdText: string, ruText: string): string => {
        return language === 'md' ? mdText : ruText;
    };

    return (
        <div
            className="relative flex flex-col items-center w-full px-4 py-24 bg-gradient-to-b from-[#ffe4e1] via-[#f4cccc] to-[#e3a3a3] mb-0.5">
            <ul className="circles absolute top-0 left-0 w-full h-full overflow-hidden -z-1">
                {circlesArray}
            </ul>
            <div
                className="relative w-full max-w-md bg-white p-10 rounded-3xl shadow-2xl z-10 overflow-hidden mb-0 sm:mb-16">
                <h2 className="text-2xl font-bold bg-[#921A40] text-white mb-8 text-center uppercase border-2 border-[#f4cccc] rounded-xl">
                    {getTranslatedText(translations.title.md, translations.title.ru)}
                </h2>
                <form onSubmit={handleSubmit} className="flex flex-col space-y-6 text-[#921A40]">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={getTranslatedText(translations.placeholderName.md, translations.placeholderName.ru)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="border border-[#c75b7a] p-4 rounded-lg focus:outline-none focus:border-[#921A40] text-xl w-full"
                            required
                        />
                    </div>
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={getTranslatedText(translations.placeholderSurname.md, translations.placeholderSurname.ru)}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            className="border border-[#c75b7a] p-4 rounded-lg focus:outline-none focus:border-[#921A40] text-xl w-full"
                            required
                        />
                    </div>
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={getTranslatedText(translations.placeholderPostalCode.md, translations.placeholderPostalCode.ru)}
                            value={adresaPostala}
                            onChange={(e) => setAdresaPostala(e.target.value)}
                            className="border border-[#c75b7a] p-4 rounded-lg focus:outline-none focus:border-[#921A40] text-xl w-full"
                            required
                        />
                    </div>
                    <div className="relative">
                        <input
                            type="tel"
                            placeholder={getTranslatedText(translations.placeholderPhoneNumber.md, translations.placeholderPhoneNumber.ru)}
                            value={nrTelefon}
                            onChange={(e) => setNrTelefon(e.target.value)}
                            className="border border-[#c75b7a] p-4 rounded-lg focus:outline-none focus:border-[#921A40] text-xl w-full"
                            required
                        />
                    </div>
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={getTranslatedText(translations.placeholderAddress.md, translations.placeholderAddress.ru)}
                            value={adresa}
                            onChange={(e) => setAdresa(e.target.value)}
                            className="border border-[#c75b7a] p-4 rounded-lg focus:outline-none focus:border-[#921A40] text-xl w-full"
                            required
                        />
                    </div>
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={getTranslatedText(translations.placeholderCity.md, translations.placeholderCity.ru)}
                            value={orasul}
                            onChange={(e) => setOrasul(e.target.value)}
                            className="border border-[#c75b7a] p-4 rounded-lg focus:outline-none focus:border-[#921A40] text-xl w-full"
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <button
                            type="submit"
                            className="w-full h-[60px] bg-[#921A40] text-white py-3 px-10 rounded-full hover:bg-[#7A1233] transition duration-300 shadow-xl text-lg uppercase tracking-wider flex items-center justify-center"
                        >
                            <FaRegEnvelope className="mr-2"/>
                            {getTranslatedText(translations.submitButton.md, translations.submitButton.ru)}
                        </button>
                    </div>
                </form>
            </div>
            <button
                type="submit"
                className="w-[150px] bg-gradient-to-r from-[#FA9884] to-[#E74646] text-white py-2 md:py-4 px-6 md:px-8 rounded-full shadow-2xl hover:scale-105 transform transition duration-500 ease-in-out sm: mt-[30px] lg: mt-[-10px]"
                onClick={handleHomeButton}

            >
                {getTranslatedText(translations.homeButton.md, translations.homeButton.ru)}
            </button>
            <p className="text-white text-center mt-2 italic">
                {getTranslatedText(translations.acasaPar.md, translations.acasaPar.ru)}
            </p>
        </div>
    );
};

export default FormularComponent;
