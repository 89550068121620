import React, { useState, useEffect } from 'react';
import blasset1 from './blasset1.png';
import blasset2 from './blasset2.png';
import blasset3 from './blasset3.png';
import blasset4 from './blasset4.png';
import { useNavigate } from 'react-router-dom';

const images = [
    blasset4,
    blasset3,
    blasset2,
    blasset1,
];

// Translation object for Romanian (md) and Russian (ru)
const translations = {
    md: {
        productName: 'B-Luron',
        productDescription: 'B-Luron este un supliment alimentar care conține un complex de acid hialuronic și condroitină. Acesta este destinat să susțină sănătatea articulațiilor și să ajute la regenerarea și protecția cartilajelor. B-Luron este adesea promovat pentru ameliorarea durerilor articulare, îmbunătățirea mobilității și menținerea flexibilității articulațiilor.',
        warning: 'Atenție, produsul include 2 sticle a câte 500ML',
        warning2: '!!! Apăsați butonul de ,,Cumpără,, pentru a completa formularul de achiziționare și pentru a fi ulterior contactat !!!',
        discount: 'Black Friday!',
        newPrice: 'Preț nou! 45% discount',
        mainIngredients: 'Ingredientele principale ale suplimentului:',
        hyaluronicAcid: 'Acidul hialuronic: un component natural al pielii și țesuturilor articulare, ajutând la menținerea hidratării și la amortizarea articulațiilor.',
        chondroitin: 'Condroitina: ajută la protejarea cartilajului și poate contribui la reducerea proceselor inflamatorii din articulații.',
        watchVideo: 'Urmărește un video despre cum se administrează:',
        recommendedFor: 'Cui îi este recomandat?',
        recommendedText: 'B-Luron este recomandat în special pentru persoanele care suferă de dureri articulare cauzate de artrită, osteoartrită sau alte afecțiuni legate de degradarea articulațiilor, dar poate fi folosit și preventiv pentru a susține sănătatea acestora, mai ales la sportivi sau la persoanele în vârstă.',
        manufacturing: 'Fabricarea acestuia:',
        potentialBenefits: 'Beneficii potențiale:',
        benefit1: 'Ameliorarea durerilor articulare: Persoanele care suferă de afecțiuni articulare precum osteoartrita pot observa o reducere a durerii și o mai bună mobilitate după administrarea de B-Luron.',
        benefit2: 'Îmbunătățirea flexibilității: Suplimentul este conceput pentru a menține articulațiile flexibile și pentru a reduce rigiditatea acestora, mai ales la persoanele în vârstă sau la sportivi care își supun articulațiile unui stres intens.',
        benefit3: 'Regenerarea cartilajului: Prin stimularea sintezei de acid hialuronic și condroitină, B-Luron poate sprijini procesele de refacere ale cartilajelor, prevenind degradarea acestora.',
        buyButton: 'Cumpără',
        discountedPrice: 2499,
        regularPrice: 4499,
    },
    ru: {
        productName: 'Б-Лурон',
        productDescription: 'B-Luron — это пищевая добавка, содержащая комплекс гиалуроновой кислоты и хондроитина. Он предназначен для поддержания здоровья суставов и способствует регенерации и защите хрящей. B-Luron часто рекламируется для облегчения боли в суставах, улучшения подвижности и поддержания гибкости суставов.',
        warning: 'Внимание, продукт включает 2 бутылки по 500 мл',
        warning2: '!!! Нажмите кнопку «Купить», чтобы заполнить форму покупки и связаться с вами позже !!!',
        discount: 'Черная Пятница!',
        newPrice: 'Новая цена! Скидка 45%',
        mainIngredients: 'Основные ингредиенты добавки:',
        hyaluronicAcid: 'Гиалуроновая кислота: естественный компонент кожи и суставных тканей, помогает поддерживать увлажнение и амортизацию суставов.',
        chondroitin: 'Хондроитин: помогает защитить хрящ и может способствовать снижению воспалительных процессов в суставах.',
        watchVideo: 'Посмотрите видео о том, как его принимать:',
        recommendedFor: 'Кому рекомендуется?',
        recommendedText: 'B-Luron особенно рекомендуется для людей, страдающих болями в суставах, вызванными артритом, остеоартритом или другими заболеваниями, связанными с разрушением суставов, но может использоваться и для профилактики, особенно у спортсменов или пожилых людей.',
        manufacturing: 'Производство:',
        potentialBenefits: 'Потенциальные преимущества:',
        benefit1: 'Облегчение боли в суставах: Люди, страдающие заболеваниями суставов, такими как остеоартрит, могут заметить снижение боли и улучшение подвижности после приема B-Luron.',
        benefit2: 'Улучшение гибкости: Добавка разработана для поддержания гибкости суставов и уменьшения их жесткости.',
        buyButton: 'Купить',
        discountedPrice: 2499,
        regularPrice: 4499,
    },
};

const ProductPageComponent: React.FC<{ language: 'md' | 'ru' }> = ({ language }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [discountedPrice, setDiscountedPrice] = useState(translations[language].discountedPrice);
    const [regularPrice, setRegularPrice] = useState(translations[language].regularPrice);
    const navigate = useNavigate();
    const handleBuyClick = () => {
        navigate('/buy');
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2500);
        return () => clearInterval(interval);
    }, []);

    const handlePrevious = () => {
        const isFirstImage = currentImageIndex === 0;
        const newIndex = isFirstImage ? images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(newIndex);
    };

    const handleNext = () => {
        const isLastImage = currentImageIndex === images.length - 1;
        const newIndex = isLastImage ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(newIndex);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            updatePrices(quantity - 1);
        }
    };

    const handleIncrement = () => {
        setQuantity(quantity + 1);
        updatePrices(quantity + 1);
    };

    const updatePrices = (newQuantity: number) => {
        setDiscountedPrice(translations[language].discountedPrice * newQuantity);
        setRegularPrice(translations[language].regularPrice * newQuantity);
    };

    return (
        <div className="main sm: h-[1800px] md:h-[1500px]">
            {/* Carousel */}
            <div
                className="flex justify-center mt-[80px] sm: ml-[20px] md:mt-[150px] sm: ml-[-170px] md:mr-[800px] sm:mr-0">
                <div className="relative">
                    <img
                        src={images[currentImageIndex]}
                        alt={`B-Luron Supplement ${currentImageIndex + 1}`}
                        className="w-72 h-72 md:w-96 md:h-96 object-cover rounded-lg shadow-md"
                    />
                </div>
            </div>

            {/* Product Info */}
            <section className="mb-8 md:ml-[400px] sm:ml-12 sm:mt-4 mt-[20px] sm: ml-[30px] md:mt-[-360px]">
                <h2 className="text-xl md:text-2xl font-semibold text-gray-800 md:ml-[300px] sm:ml-0">{translations[language].productName}</h2>
                <br/>
                <p className="text-gray-600 md:w-[500px] md:ml-[300px] sm:ml-0 sm:w-full">
                    {translations[language].productDescription}
                </p>
                <br/>
                <h1 className="text-sm font-semibold text-orange-600 md:ml-[300px] sm:ml-0">{translations[language].warning}</h1>
                <br/>
                <h1 className="text-lg font-semibold text-orange-900 md:ml-[300px] sm:ml-0">{translations[language].discount}</h1>
                <br/>
                <h1 className="text-sm font-semibold text-orange-900 md:ml-[460px] sm:ml-0 sm:mt-2 md:mt-[-45px]">{translations[language].newPrice}</h1>
                <br/>
            </section>

            {/* Price, Buy Button, and Quantity Selector */}
            <div
                className="flex flex-row sm:flex-row justify-center mt-[-40px] md:ml-[470px] items-center mb-8 sm:ml-[14px] sm:mt-[-30px] sm:mt-4 space-y-4">
                {/* Quantity Selector */}
                <div
                    className="flex items-center border border-[#E74646] rounded-lg p-2 w-24 sm:w-32 justify-between md:mt-[15px] sm: mt-[15px] sm: ml-[10px]">
                    <button onClick={handleDecrement}
                            className="text-xl sm:text-2xl font-bold text-orange-900 hover:text-[#FA9884]">-
                    </button>
                    <span className="text-lg sm:text-xl">{quantity}</span>
                    <button onClick={handleIncrement}
                            className="text-xl sm:text-2xl font-bold text-orange-900 hover:text-[#FA9884]">+
                    </button>
                </div>

                {/* Prices */}
                <div className="flex flex-row items-center space-x-4 sm: ml-[17px]">
                    <div className="border border-[#E74646] rounded-lg p-2">
                        <span className="text-xl sm:text-3xl font-bold text-orange-900">{discountedPrice} MDL</span>
                    </div>
                    <div className="border border-[#E74646] rounded-lg p-2">
                        <span
                            className="text-xl sm:text-3xl font-bold text-orange-900 line-through">{regularPrice} MDL</span>
                    </div>
                </div>

                {/* Buy Button */}
                <button
                    className="bg-gradient-to-r from-[#FA9884] via-[#FFB3A4] to-[#FFD4B5] text-white px-4 sm: ml-[8px] sm:px-6 py-2 rounded-full
                    hover:bg-blue-500 shadow-2xl hover:scale-105 transform transition duration-500 ease-in-out"
                    onClick={handleBuyClick}
                >
                    {translations[language].buyButton}
                </button>
            </div>
            <h1 className="text-xl font-semibold text-orange-600 md:ml-[700px] sm:ml-0">{translations[language].warning2}</h1>

            {/* Product Details */}
            <section className="mb-8 mt-[100px] sm: ml-[30px] md:ml-[150px]">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{translations[language].mainIngredients}</h3>
                <ul className="list-disc list-inside text-gray-600">
                    <li>{translations[language].hyaluronicAcid}</li>
                    <li>{translations[language].chondroitin}</li>
                </ul>
            </section>

            {/* Video Section */}
            <section className="mb-8 sm:ml-[20px] md:ml-[150px]">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{translations[language].watchVideo}</h3>
                <div className="w-full md:w-[460px] h-[215px]">
                    <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/bUNWFoM9Vx0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </section>
            <section className="md:ml-[800px] sm: ml-[30px] sm: mt-[150px] md:mt-[-250px]">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{translations[language].recommendedFor}</h3>
                <ul className="list-disc list-inside text-gray-600">
                    <p>{translations[language].recommendedText}</p>
                </ul>
            </section>
            <section className="mb-8 sm:ml-[20px] md:ml-[800px] md: mt-[60px]">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{translations[language].manufacturing}</h3>
                <div className="w-[460px] h-[315px]">
                    <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/f7Ix2PsfmKI"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </section>
            <section className="mb-8 md:mt-[-20px] sm: ml-[30px] md:ml-[150px] sm: mt-[50px]">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">{translations[language].potentialBenefits}</h3>
                <ul className="list-disc list-inside text-gray-600">
                    <li>{translations[language].benefit1}</li>
                    <li>{translations[language].benefit2}</li>
                </ul>
            </section>
        </div>
    );
};

export default ProductPageComponent;
